<template>
  <div>
    <!-- picC7BBE9F1-DE01-42C2-AD90-AD3675D3BEDA.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/signup-for-closed-beta.jpg">
    <p>
      Get the chance to be selected as a game tester!
    </p>
    <p>
      We frequently invite new testers into LEGO Universe…here’s your chance to get your name on the list of potential testers. If chosen, you could become one of the first LEGO minifigures to play and build in the first Massively Multiplayer Online Game (MMOG) for LEGO fans!
    </p>
    <p>
      Just click
      <router-link :to="{ name: 'beta' }">here</router-link> and you’re on your way!
    </p>
  </div>
</template>
